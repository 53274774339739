import React, { useState } from "react";
  
import "./FormRegister.css"; // Import the CSS file for styling
 
import pent from "./pewglogo.png"
 
import Swal from 'sweetalert2';

import dlogo from './noimage.png';

import { api } from '../Api';
import axios from "axios";
function FormRegister() {
  // const [imageSrc, setImageSrc] = useState('');

  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState(null);
  const [currentStep, setCurrentStep] = useState(1);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [gender, setGender] = useState("");
  const [COPMember, setCOPMember] = useState(null);
  const [churchArea, setChurchArea] = useState("");
  const [district, setDistrict] = useState("");
  const [local, setLocal] = useState("");
  const [otherReligion, setOtherReligion] = useState("");
  const [course, setCourse] = useState("");
  const [base64Image, setBase64Image] = useState('');
   const [Member_Status, setMember_Status] = useState("");

  const [Profession, setProfession] = useState("");
  const [OtherProfession, setOtherProfession] = useState("");

  const [showOtherChurchArea, setShowOtherChurchArea] = useState(false);
  const [otherChurchArea, setOtherChurchArea] = useState("");
  
  const [copguild, setCopguild] = useState("");
  const [Studentschool, setStudentschool] = useState("");
  const [StudentCourse, setStudentCourse] = useState("");
  
  const [StudentLevel, setStudentLevel] = useState("");



  
  const [showOtherChurchGuild, setShowOtherChurchGuild] = useState(false);
  
  const [otherChurchGuild, setOtherChurchGuild] = useState("");

  const [OtherMemberStatus, setOtherMemberStatus] = useState("");

  


  const handleFileInputChange = (e) => {
    const selectedImage = e.target.files[0];
    
    if (selectedImage) {
      if (selectedImage.size > 400 * 1024) {
        // File size is larger than 400KB, clear the input
        e.target.value = '';
        setImage(null);
        // alert('File size should be no larger than 400KB');
        const Toast = Swal.mixin({
          toast: true,
          position: 'top-right',
            iconColor: 'red',
          customClass: {
              popup: 'colored-toast'
          },
          showConfirmButton: false,
          timer: 5500,
          timerProgressBar: true
      })
      Toast.fire({
          type: "error",
          icon: 'error',
          color: "red",
        //   background: 'red',
          title: 'File size should be no larger than 400KB ',
          // text: 'Something went wrong!', email and password must be filled out
      })
 
        return;
      }

      const imageURL = URL.createObjectURL(selectedImage);
      setImage(imageURL);
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        setBase64Image(reader.result);
      };
    }
  };
 


  const getErrorMessage = () => {
    if (currentStep === 1) {
      if (!firstName || !/^[a-zA-Z0-9\s\-_:]+$/.test(firstName)) {
        return "Invalid first name.";
      }
    
      if (!lastName || !/^[a-zA-Z\s\-_:]+$/.test(lastName)) {
        return "Invalid last name.";
      }

    // if (!email || !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-z]{2,}$/.test(email)) {
    //   return "Email validation error.";
    // }

    // if (!phoneNumber || !/^[\d\s\-]+$/.test(phoneNumber)) {
    //   return "Phone number validation error.";
    // }
    if (!phoneNumber || !/^\+?[\d\s\-]+$/.test(phoneNumber)) {
      return "Phone number validation error.";
    }

    if (!gender) {
        return "Gender is required.";
    }

      
    }
    // if (!email || !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-z]{2,}$/.test(email)) {
    //   return "Email validation error.";
    // }

    if (currentStep === 2) {
        // if (!COPMember) {
        //     return "COPMember field is required.";
        // }
        
        // if (!churchArea || !district || !local) {
        //     return "Please fill out all church details  .";
        // }
         
          // Modify the regular expressions to accept alphanumeric, space, hyphen, underscore, and colon
          if (!churchArea || !/^[a-zA-Z0-9\s\-_:]+$/.test(churchArea)) {
            return "Invalid church area.";
          }
        
          if (!district || !/^[a-zA-Z0-9\s\-_:]+$/.test(district)) {
            return "Invalid district.";
          }
        
          if (!local || !/^[a-zA-Z0-9\s\-_:]+$/.test(local)) {
            return "Invalid local.";
          }


          if (churchArea === "Other" && (!otherChurchArea)) 
          {
            return "Specify the church area.";
          }
       
      
    }

    if (currentStep === 3 && !copguild) 
    {
        return "cop guild is required in step 3.";
    }

    if (copguild === "Other" && (!otherChurchGuild)) 
    {
      return "Specify the church Guild.";
    }



    if (currentStep === 3 && !Member_Status) {
      return "Member Status is required in step 3.";
  }

  if (Member_Status === "Other" && (!OtherMemberStatus)) 
  {
    return "Specify the Member Status.";
  }


  if (currentStep === 3 && !Profession) {
    return "Select your Profession.";
}

if (Profession === "Other" && (!OtherProfession)) 
{
  return "Specify the Profession.";
}





  if (Member_Status === "Student" && (!Studentschool || !StudentCourse || !StudentLevel)) 
  {
    return "Specify the Student details.";
  }

    return null;  // No errors found
};

const isFormValid = () => {
    return getErrorMessage() === null;
};

const nextStep = () => {
    const errorMessage = getErrorMessage();
    if (errorMessage) {
        const Toast = Swal.mixin({
            toast: true,
            position: 'top-right',
            iconColor: 'red',
            customClass: {
                popup: 'colored-toast'
            },
            showConfirmButton: false,
            timer: 5500,
            timerProgressBar: true
        });
        Toast.fire({
            type: "error",
            icon: 'error',
            title: errorMessage,
        });

        return;
    }
    setCurrentStep(currentStep + 1);
};
 


  const prevStep = () => {
    setCurrentStep(currentStep - 1);
  };

 


  const handleToast = (icon, title, color) => {
    const Toast = Swal.mixin({
      toast: true,
      position: 'top-right',
      iconColor: color,
      customClass: {
        popup: 'colored-toast'
      },
      showConfirmButton: false,
      timer: 5500,
      timerProgressBar: true
    });
  
    Toast.fire({ icon, title });
  }
  
  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
  
    if (!phoneNumber || !firstName) {
      setLoading(false);
      handleToast('error', 'Form validation error!', 'red');
    } else {
      const data = {
        firstName,
        copguild :showOtherChurchGuild ? otherChurchGuild : copguild,
        lastName,
        email,
        phoneNumber,
        gender,
        COPMember,
        churchArea: showOtherChurchArea ? otherChurchArea : churchArea,
        memberstatus: Member_Status === 'Other' ? OtherMemberStatus : Member_Status,
        profession: Profession === 'Other' ? OtherProfession : Profession,
        district,
        local,
        otherReligion,
        course,
        base64Image,
        Studentschool,
        StudentCourse,
        StudentLevel
      };
      api.post('/api/register', data, {
      // axios.post('http://localhost:8000/api/register', data, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded',
          // 'Authorization': 'Bearer YOUR_TOKEN' // if needed
        }
      })
      .then(res => {
        if (res && res.data && res.data.message) {
          handleToast('success', res.data.message, 'green');
          setImage(/* Your dlogo or any other image */);
          setLoading(false);
          setTimeout(() => { window.location = "/thankyou"; }, 7000);
        } else if (res && res.response && res.response.data && res.response.data.response && res.response.data.response.message) {
          setLoading(false);
          handleToast('error', 'Error: ' + res.response.data.response.message, 'red');
        } else {
          setLoading(false);
          handleToast('error', 'Error: An unknown error occurred', 'red');
        }
      })
      .catch(err => {
        setLoading(false);
 
 
          setLoading(false);
        
          // First, fix the typo: change 'errponse' to 'response'
          if (err && err.response && err.response.data && err.response.data.message) {
            handleToast('error', 'Error: ' + err.response.data.message, 'red');
          } else {
            //  alert(err.response.data.response.message)
            
        const { status, data } = err.response;

        if(status===403){
          handleToast('error', 'This user account is already in use!', 'red');
           }
          else if (status === 409) {
            handleToast('error', 'This user account is already in use!', 'red');
          
           }

           else if (status === 400) {
            handleToast('error', 'Bad Request: Please check your input!', 'red');
          
           }

           else if (status === 404) {
            handleToast('error', 'Not Found: The requested resource was not found!', 'red');
          
           }

           else if (status === 500) {
            handleToast('error', 'Internal Server Error: Something went wrong on our end!', 'red');
          
           }

         else{
         handleToast('error', 'Error: Connection fail', 'red');
           }
         

          }
         
        // const errorMessage = err.response?.data?.message || 'An error occurred';
        // handleToast('error', 'Error: ' + errorMessage, 'red');
      });
    }
  }
  


 



  return (
    <> 

    {loading ? (
  <div class="containers"> 
    <div   class="loading"></div></div>

            ) : (<></>)}

      {/* ---------------Navigation------------ */}
      <div>
        <div  className='horizontal-nav'>
          <nav className="navbar navbar-expand-lg">
            <div id="nx-nav" className="container">
              <i class='bx bx-menu bx-lg'
               className="navbar-toggler" type="button"
                data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" 
                aria-controls="navbarNavDropdown" aria-expanded="false"
                 aria-label="Toggle navigation">
            
                <i class='bx bx-menu bx-lg'></i>
              </i>
              <div className="collapse navbar-collapse" id="navbarNavDropdown">
                <ul className="navbar-nav">
                  <li className="nav-item active">
           
                    <a href="#">Home</a>
                  </li>
                  <li id='nav-li-id' >
                    <a href="####"><img src={pent} alt="logo" id='logo' /></a> 
                  </li>
                </ul>
 
              </div>
            </div>
          </nav>

          
        </div>
      </div>

      {/* ---------------Navigation------------ */}
      {/* ================================================================================== */}
    
      <div id="#signup-sec" className="container-fluid">


        <form onSubmit={handleSubmit} id="myform">
          {/* Step 1 */}
          {currentStep === 1 && (
            <>
              <div className="form-group">
                <h6 id="hd" >PeWG Registration Portal </h6>
                {/* =========================================================== */}
                {/* <h5>  {error && <p>{error}</p>}</h5> */}
                <div id='imgUpload'>
                    <div>
                      <div>
                      <img width="100" height="100" id="previewImg"
                             src={image || dlogo} alt='copitguild'
                         
                      class="img-responsive rounded-circle center-block d-block mx-auto"
                        onError={(e) => { e.target.onerror = null; e.target.src = dlogo }}
                       />
                                                        
          <input type="file" id="image" onChange={handleFileInputChange} />
                      </div>
                    </div>
                  </div>

                {/* =========================================================== */}
                <div class="row">
                <div class="col-md-6">
                <label>
                  First Name *
                  <div id="fn">
                    <i style={{marginTop:"16px"}} class='bx bx-user bx-sm '></i>
                    <input placeholder="Enter First Name" type="text" value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                      className={!firstName ? "error" : ""}
                      class="form-control"
                      required />
                  </div>

                </label>
                </div>
 
                <div class="col-md-6">
                <label>
                  Last Name *
                  <div id="fn">
                    <i style={{marginTop:"16px"}}  class='bx bxs-user bx-sm '></i>
                    <input placeholder="Enter Last Name" type="text" value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                      className={!lastName ? "error" : ""}
                      class="form-control" required />
                  </div>
                </label>
                </div>
                </div>
                {/* ================================================ */}

                <div class="row">
               
                <label>
                  Email :

                  <div id="fn">
                    <i style={{marginTop:"16px"}}class='bx bxs-envelope bx-sm' ></i>
                    <input placeholder="Enter Email" type="email" value={email}
                      onChange={(e) => setEmail(e.target.value)}
                       
                      class="form-control"  />
                  </div>
                </label>
                </div>

                {/* =========================================================== */}
                <div class="row">
                <div class="col-md-6">
                <label>
                  Phone Number *
                  <div id="fn">
                    <i style={{marginTop:"16px"}} class='bx bxs-phone bx-sm'></i>
                    <input placeholder="Enter mobile phone Number" type="number" value={phoneNumber}
                      onChange={(e) => setPhoneNumber(e.target.value)}
                      className={!phoneNumber ? "error" : ""}
                      class="form-control" required />
                  </div>
                </label>
                </div>


                <div class="col-md-6">
                <label>
                  Gender *
                  <div id="fn">
                    <i style={{marginTop:"16px"}} class='bx bx-male-female  bx-sm' ></i>
                     <select class="form-control"
                      value={gender} onChange={(e) => setGender(e.target.value)} 
                     className={!gender ? "error" : ""}   required>
                      <option value="">--Select Gender--</option>
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                    </select>
                  </div>
                </label>
                </div>
                </div>



              </div>

              <button type="button" className="btn btn-primary" onClick={nextStep}>Next</button>
            </>
          )}


          {/* Step 2 */}
          {currentStep === 2 && (
            <>
                <h6 id="hd" >PeWG Registration Portal</h6>
                 
              <h4>Step 2</h4>
             
              
                <>
                <label>
  Select a Church Area / Sector *
  <div id="fn">
    <i style={{marginTop:"16px"}}  class='bx bx-grid bx-sm'></i>
    <select class="form-control" value={churchArea} onChange={(e) => {
      setChurchArea(e.target.value);
      if (e.target.value === "Other") {
        setShowOtherChurchArea(true);
      } else {
        setShowOtherChurchArea(false);
        setOtherChurchArea(""); // Clear the input field value
      }
    } } >
        <option value="">--Select--</option>
              <option value="Achimota">Achimota</option>
                        <option value="Abuakwa" >Abuakwa</option>
                        <option value="Afram-Plains" >Afram-Plains</option>
                        <option value="Aflao">Aflao</option>
                        <option value="Agona-Swedru">Agona-Swedru</option>
                        <option value="Agormanya">Agormanya</option>
                        <option value="Akim-Oda">Akim-Oda</option>
                        <option value="Anyaa-Ablekuma">Anyaa-Ablekuma</option>
                        <option value="Asankrangwa">Asankrangwa</option>
                        <option value="Ashaiman">Ashaiman</option>
                        <option value="Asante-Bekwai">Asante-Bekwai</option>
                        <option value="Asokwa">Asokwa</option>
                        <option value="Assin-Fosu">Assin-Fosu</option>
                        <option value="Atebubu">Atebubu</option>
                        <option value="Atonsu">Atonsu</option>
                        <option value="Axim">Axim</option>
                        <option value="Bawku">Bawku</option>
                        <option value="Berekum">Berekum</option>
                        <option value="Bogoso">Bogoso</option>
                        <option value="Bolgatanga">Bolgatanga</option>
                        <option value="Bompata">Bompata</option>
                        <option value="Bompata-Sector">Bompata-Sector</option>
                        <option value="Capecoast">Capecoast</option>
                        <option value="Capecoast-Sector">Capecoast-Sector</option>
                        <option value="Dansoman">Dansoman</option>
                        <option value="Downtown-Ofankor">Downtown-Ofankor</option>
                        {/* <option value="Downtown-Ofaakor">Downtown-Ofaakor</option> */}
                        <option value="Dormaa-Ahenkro">Dormaa-Ahenkro</option>
                        <option value="Duayaw-Nkwanta">Duayaw-Nkwanta</option>
                        <option value="Duankwa"uankwa>Duankwa</option>
                        <option value="Ejisu">Ejisu</option>
                        <option value="Essam-Debiso">Essam-Debiso</option>
                        <option value="Goaso">Goaso</option>
                        <option value="Haatso">Haatso</option>
                        <option value="Ho">Ho</option>
                        <option value="Hohoe">Hohoe</option>
                        <option value="Juaboso">Juaboso</option>
                        <option value="Kade">Kade</option>
                        <option value="Kaneshie">Kaneshie</option>
                        <option value="Kaneshie-Sector">Kaneshie-Sector</option>
                        <option value="Kasoa">Kasoa</option>
                        <option value="Kete-Krachi">Kete-Krachi</option>
                        <option value="Kintampo">Kintampo</option>
                        <option value="Koforidua">Koforidua</option>
                        <option value="Koforidua">Koforidua-Effiduase</option>
                        <option value="Konongo">Konongo</option>
                        <option value="Kwadaso">Kwadaso</option>
                        <option value="Kwadaso-Sector">Kwadaso-Sector</option>
                        <option value="La">La</option>
                        <option value="Lower-Volta-Sector">Lower-Volta-Sector</option>
                        <option value="Madina">Madina</option>
                        <option value="Madina-Sector">Madina-Sector</option>
                        <option value="Mampong">Mampong</option>
                        <option value="Mampong-Sector">Mampong-Sector</option>
                        <option value="Mankessim-Sector">Mankessim-Sector</option>
                        <option value="Mankessim">Mankessim</option>
                        <option value="New-Tafo">New-Tafo</option>
                        <option value="Nkawkaw">Nkawkaw</option>
                        <option value="Northern-sector">Northern-sector</option>
                        <option value="Nsawam">Nsawam</option>
                        <option value="Obuasi">Obuasi</option>
                        <option value="Odorkor">Odorkor</option>
                        <option value="Offinso">Offinso</option>
                        <option value="Old-Tafo">Old-Tafo</option>
                        <option value="Pensa-Knust">Pensa-Knust</option>
                        <option value="Pensa-Legon">Pensa-Legon</option>
                        <option value="Pensa-Ucc ">Pensa-Ucc </option>
                        <option value="Pensa-Uew">Pensa-Uew </option>
                        <option value="Sawla">Sawla</option>
                        <option value="Sefwi-Bekwai">Sefwi-Bekwai</option>
                        <option value="Sefwi-Juabuso">Sefwi-Juabuso </option>
                        <option value="Sefwi-Wiawso">Sefwi-Wiawso</option>
                        <option value="Sekondi">Sekondi</option>
                        <option value="Sogakope">Sogakope</option>
                        <option value="Suame">Suame</option>
                        <option value="Suhum">Suhum</option>
                        <option value="Suhum-Sector">Suhum-Sector</option>
                        <option value="Sunyani">Sunyani</option>    
                        <option value="Takoradi">Takoradi</option>
                        <option value="Tamale">Tamale</option>
                        <option value="Takoradi-Sector">Takoradi-Sector</option>
                        <option value="Techiman">Techiman</option>
                        <option value="Techiman-Sector">Techiman-Sector"</option>
                        <option value="Tema">Tema</option>
                        <option value="Tepa">Tepa</option>
                        <option value="Tarkwa">Tarkwa</option>
                        <option value="Teshie-Nungua">Teshie-Nungua</option>
                        <option value="Tumu">Tumu</option>
                        <option value="Twifi-Praso">Twifi-Praso</option>
                        <option value="Upper-East-Sector">Upper-East-Sector</option>
                        <option value="Upper-West-Sector">Upper-West-Sector</option>
                        <option value="Upper-Volta-Sector">Upper-Volta-Sector</option>
                        <option value="Wa">Wa</option>
                        <option value="Walewale">Walewale</option>
                        <option value="Winneba">Winneba</option>
                        <option value="Yendi">Yendi</option>

      <option value="Other">Other</option>
    </select>
  </div>
</label>

{showOtherChurchArea && (

  <label>

    Other Church Area:
    <div id="fn">
      <i style={{marginTop:"16px"}} class='bx bx-pencil bx-sm'></i>
      <input
        type="text"
        value={otherChurchArea}
        onChange={(e) => setOtherChurchArea(e.target.value)}
         
        class="form-control"
        placeholder="Other (specify)"
        required
      />
    </div>
  </label>
)}

 
                  <label>
                    District *
                    <div id="fn">
                      <i style={{marginTop:"16px"}}  class='bx bx-card bx-sm'></i>
                     
                    <input placeholder="Enter Church District" type="text" value={district} onChange={(e) => setDistrict(e.target.value)} />
                    </div>
                  </label>
                  <label>
                    Local *
                    <div id="fn">
                      <i style={{marginTop:"16px"}}  class='bx bx-slider-alt bx-sm'></i>
                     
                    <input placeholder="Enter Local Assembly" type="text" value={local} onChange={(e) => setLocal(e.target.value)} />
                    </div>
                  </label>

                

                </>
            
              <button type="button" className="btn btn-secondary" onClick={prevStep}>Previous</button>
              <button type="button" className="btn btn-primary" onClick={nextStep}>Next</button>
            </>
          )}


          {/* Step 3 */}
          {/* Step 3 */}
{currentStep === 3 && (
    <>
        <h6 id="hd">PeWG Registration Portal</h6>
        <br />
        <div className="form-group">
            <h6>Finish up </h6>







            <div class="col-md-12">
                <label>
                Select your Profession *
                  <div id="fn">
                    <i style={{marginTop:"16px"}} class='bx bx-money-withdraw  bx-sm' ></i>
                     <select class="form-control"
                       value={Profession}
                       onChange={(e) => setProfession(e.target.value)}
                     >
              
  <option value="">--Select Profession--</option>
    <option value="Accountant">Accountant</option>
    <option value="Actor">Actor</option>
    <option value="Agriculturalist">Agriculturalist</option>
    <option value="Air Traffic Controller">Air Traffic Controller</option>
    <option value="Architect">Architect</option>
    <option value="Artist">Artist</option>
    <option value="Astronaut">Astronaut</option>
    <option value="Biomedical Engineer">Biomedical Engineer</option>
    <option value="Brewer">Brewer</option>
    <option value="Business Person">Business Person</option>
    <option value="Carpenter">Carpenter</option>
    <option value="Chef">Chef</option>
    <option value="Chemist">Chemist</option>
    <option value="Civil Engineer">Civil Engineer</option>
    <option value="Clinical Psychologist">Clinical Psychologist</option>
    <option value="Content Creator">Content Creator</option>
    
    <option value="Data Scientist">Data Scientist</option>
    <option value="Dentist">Dentist</option>
    <option value="Electrician">Electrician</option>
    <option value="Economist">Economist</option>
    <option value="Engineer">Engineer</option>
    <option value="Environmental Scientist">Environmental Scientist</option>
    <option value="Event Planner">Event Planner</option>
    
    <option value="Entrepreneur">Entrepreneur</option>

    <option value="Fashion Designer">Fashion Designer</option>
    <option value="Financial Analyst">Financial Analyst</option>
    <option value="Firefighter">Firefighter</option>
    <option value="Farmer">Farmer</option>
    <option value="Fisherperson">Fisherperson</option>
    <option value="Forensic Scientist">Forensic Scientist</option>
    <option value="Geneticist">Geneticist</option>
    <option value="Geologist">Geologist</option>
    <option value="Graphic Designer">Graphic Designer</option>
    <option value="Hairdresser">Hairdresser</option>
    <option value="Health Educator">Health Educator</option>
    <option value="Herbalist">Herbalist</option>
    <option value="IT Professional">IT Professional</option>
    <option value="Journalist">Journalist</option>
    <option value="Lawyer">Lawyer</option>
    <option value="Linguist">Linguist</option>
    <option value="Lecturer">Lecturer</option>
    
    <option value="Marketing Specialist">Marketing Specialist</option>
    <option value="Mechanic">Mechanic</option>
    <option value="Mechanical Engineer">Mechanical Engineer</option>
    <option value="Medical Doctor">Medical Doctor</option>
    <option value="Meteorologist">Meteorologist</option>
    <option value="Military">Military</option>
    <option value="Miner">Miner</option>
    <option value="Mobile App Developer">Mobile App Developer</option>
    <option value="Nurse">Nurse</option>
    <option value="Occupational Therapist">Occupational Therapist</option>
    <option value="Oil and Gas Engineer">Oil and Gas Engineer</option>
    <option value="Paramedic">Paramedic</option>
    <option value="Pharmacist">Pharmacist</option>
    <option value="Phlebotomist">Phlebotomist</option>
    <option value="Photographer">Photographer</option>
    <option value="Physiotherapist">Physiotherapist</option>
    <option value="Pilot">Pilot</option>
    <option value="Policeman">Policeman</option>
    <option value="Policy Maker">Policy Maker</option>
    <option value="Psychologist">Psychologist</option>
    <option value="Public Relations Specialist">Public Relations Specialist</option>
    <option value="Quality Assurance Analyst">Quality Assurance Analyst</option>
    <option value="Radiologist">Radiologist</option>
    <option value="Scientist">Scientist</option>
    <option value="Social Media Manager">Social Media Manager</option>
    <option value="Social Worker">Social Worker</option>
    <option value="Software Developer">Software Developer</option>
    <option value="Solar Panel Technician">Solar Panel Technician</option>
    <option value="Speech Therapist">Speech Therapist</option>
    <option value="Surveyor">Surveyor</option>
    <option value="Teacher">Teacher</option>
    <option value="Textile Designer">Textile Designer</option>
    <option value="Tour Guide">Tour Guide</option>
    <option value="Tourism Consultant">Tourism Consultant</option>
    <option value="Tourism Manager">Tourism Manager</option>
    <option value="Traditional Weaver">Traditional Weaver</option>
    <option value="Translator">Translator</option>
    <option value="UX/UI Designer">UX/UI Designer</option>
    <option value="Veterinarian">Veterinarian</option>
    <option value="Waste Management Specialist">Waste Management Specialist</option>
    <option value="Web Designer">Web Designer</option>
    <option value="Zoologist">Zoologist</option>
    <option value="Other">Other</option>
                    </select>
                  </div>
                </label>
                <div class="col-md-12 m-1">
              {Profession === 'Other' && (
                        <>
                          <input
                      type="text"
                      value={OtherProfession}
                      onChange={(e) => setOtherProfession(e.target.value)}
                      className="form-control"
                      placeholder="Other (specify)" />
                        </> 
                      )}
              </div>
                </div>









            <label>
            <div>
                    <label> 
                     
                    Select your Guild *
                    <div id="fn">
                    <i style={{marginTop:"16px"}} class='bx bx-grid-alt bx-sm' ></i> 
                    <select class="form-control"   value={copguild} 
                    onChange={(e) => { 
                    setCopguild(e.target.value);
                    if (e.target.value === "Other") {
                       
                      setShowOtherChurchGuild(true);
                    } else {
                      setShowOtherChurchGuild(false);
                      setOtherChurchGuild(""); // Clear the input field value
                    }
                   
                  
                     } } 
                      >
                    <option value="">--Select--</option>
                    <option value="Health">Health</option>
                      <option value="Business">Business</option>
                      <option value="Build Environment">Build Environment</option>
                      <option value="Music and Entertainment">Music and Entertainment</option>
                      <option value="Education">Education</option>
                      <option value="Banking and Finance">Banking and Finance</option>
                      <option value="Media">Media</option>
                      <option value="Informtion Technology">Informtion Technology(IT)</option>
                      <option value="Fashion">Fashion</option>
                      <option value="Hospitality and Tourism">Hospitality and Tourism</option>
                      <option value="Social and Philantropy">Social and Philantropy</option>
                      <option value="Security">Security</option>
                      <option value="Legal and Judiciary">Legal and Judiciary</option>
                      <option value="Transport">Transport</option>
                      <option value="Farmers">Farmers</option>
                      <option value="Fisherman">Fisherman</option>
                      <option value="Market Woman">Market Woman</option>
                      <option value="Politicians">Politicians</option>
                      <option value="Mechanics">Mechanics</option>
                      <option value="Mining">Mining</option>
                      <option value="Technicians">Technicians</option>
                      <option value="Other">Other</option>
                    </select>
                    </div>
                   
                    </label>

                    {showOtherChurchGuild && (

<label>

  New Guild:
  <div id="fn">
    <i style={{marginTop:"16px"}} class='bx bx-pencil bx-sm'></i>
    <input
      type="text"
      value={otherChurchGuild}
      onChange={(e) => setOtherChurchGuild(e.target.value)}
       
      class="form-control"
      placeholder="Other (specify)"
      required
    />
  </div>
</label>
)}





                  </div>
            </label>
        </div>

        <div className="form-group">
          <label>
          Member Status *
          <div id="fn">
                    <i style={{marginTop:"16px"}} class='bx bx-user bx-sm' ></i> 
          
                <select 
                 value={Member_Status}
                 onChange={(e) => setMember_Status(e.target.value)}
                  >
                  <option value="">--Select-- </option>
                  <option value="Member">Member</option>
                  <option value="Student">Student</option>
                 
                  <option value="National Executive  Member">National Executive  Member</option>
                    <option value="Area Coordinator">Area Coordinator</option>
                  <option value="Area Executive Member">Area Executive Member</option>
                   <option value="District Coordinator">District Coordinator</option>
                  <option value="District Executive Member">District Executive Member</option>

                  <option value="Local Coordinator">Local Coordinator</option>
                  <option value="Local Executive Member">Local Executive Member</option>
                  <option value="Other">Other</option>
                   
                
                  </select>
                  </div>
        
              </label>

              <div class="col-md-12 m-1">
              {Member_Status === 'Other' && (
                        <>
                          <input
                      type="text"
                      value={OtherMemberStatus}
                      onChange={(e) => setOtherMemberStatus(e.target.value)}
                      className="form-control"
                      placeholder="Other (specify)" />
                        </> 
                      )}
              </div>
          
          
              <div class="col-md-12 m-1">
                      {Member_Status === 'Student' && (
                        <><><input
                      type="text"
                      value={Studentschool}
                      onChange={(e) => setStudentschool(e.target.value)}
                      className="form-control"
                      placeholder="Enter your school name" />
                      <input
                        type="text"
                        value={StudentCourse}
                        onChange={(e) => setStudentCourse(e.target.value)}
                        className="form-control"
                        placeholder="Enter Course eg. Computer Science " />
                      <br /></><input
                        type="text"
                        value={StudentLevel}
                        onChange={(e) => setStudentLevel(e.target.value)}
                        className="form-control"
                        placeholder="Enter Student Level eg. Level 100 ,  " /></>
 
 
                      )}
                    </div>
          
              </div>
          
       
        <div className="form-group">
          
         {/* Applicant's Consent */}
    <div className="form-group">
      <label style={{ color: "red", marginLeft: "10px" }}>Applicant's Consent</label>
      <h6  htmlFor="terms">
        By completing this form, I hereby provide my explicit consent to the
         COP Workers Guild for the utilization of my personal information exclusively for 
         the purposes related to this registration.
      </h6>
    </div>
        </div>

        <button type="button" className="btn btn-secondary" onClick={prevStep}>Previous</button>
        <button type="submit" className="btn btn-primary" disabled={!isFormValid()}>Submit</button>
    </>
)}

        </form>
      </div>



    </>

  );
}

export default FormRegister;
