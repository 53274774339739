import React, { useState } from "react";
import axios from "axios"; // Import Axios for making HTTP requests

const CheckboxGroup = () => {
  const [baptismWaterChecked, setBaptismWaterChecked] = useState(false);
  const [baptismSpiritChecked, setBaptismSpiritChecked] = useState(false);
  const [baptismNoneChecked, setBaptismNoneChecked] = useState(false);

  const handleBaptismWaterChange = (e) => {
    setBaptismWaterChecked(e.target.checked);
    if (e.target.checked) {
      setBaptismNoneChecked(false);
    }
  };

  const handleBaptismSpiritChange = (e) => {
    setBaptismSpiritChecked(e.target.checked);
    if (e.target.checked) {
      setBaptismNoneChecked(false);
    }
  };

  const handleBaptismNoneChange = (e) => {
    setBaptismNoneChecked(e.target.checked);
    if (e.target.checked) {
      setBaptismWaterChecked(false);
      setBaptismSpiritChecked(false);
    }
  };

  const sendPostRequest = (selectedOptions) => {
    alert(selectedOptions + "Post successful!");
    // axios
    //   .post("YOUR_API_ENDPOINT", { options: selectedOptions })
    //   .then((response) => {
    //     alert("Post successful!");
    //     // You can handle the response from the server here if needed
    //   })
    //   .catch((error) => {
    //     alert("An error occurred while making the POST request.");
    //     console.error(error);
    //   });
  };

  const handleSubmit = () => {
    const selectedOptions = [];
    if (baptismWaterChecked) {
      selectedOptions.push("Baptism Water");
    }
    if (baptismSpiritChecked) {
      selectedOptions.push("Holy Spirit Baptism");
    }
    if (baptismNoneChecked) {
      selectedOptions.push("None of the above");
    }

    if (selectedOptions.length === 0) {
      alert("No option is selected");
    } else {
      // Call the function to send the POST request
   //   sendPostRequest(selectedOptions);
    }
 alert(selectedOptions + 12345678);

  };

  // Determine if the "Submit" button should be disabled
  const isSubmitDisabled = !(baptismWaterChecked || baptismSpiritChecked || baptismNoneChecked);

  return (
    <div>
      <div style={{ display: baptismNoneChecked ? "none" : "block" }}>
        <input
          className="form-check-input"
          type="checkbox"
          id="Baptism_Water1"
          name="Baptism_Water1"
          value="Baptism Water"
          checked={baptismWaterChecked}
          onChange={handleBaptismWaterChange}
        />{" "}
        <b> Check Water Baptism</b>
        <br />
        <input
          className="form-check-input"
          type="checkbox"
          id="Baptism_Spirit2"
          name="Baptism_Spirit2"
          value="Baptism Spirit"
          checked={baptismSpiritChecked}
          onChange={handleBaptismSpiritChange}
        />&nbsp; &nbsp;
        <b> Check Holy Spirit Baptism</b>
        <br />
      </div>

      {!baptismWaterChecked && !baptismSpiritChecked && (
        <>
          <input
            className="form-check-input"
            type="checkbox"
            id="none"
            name="none3"
            value="none"
            checked={baptismNoneChecked}
            onChange={handleBaptismNoneChange}
          />{" "}
          <b> None of above </b>
          <br />
        </>
      )}

      <button onClick={handleSubmit} disabled={isSubmitDisabled}>
        Submit
      </button>
    </div>
  );
};

export default CheckboxGroup;
