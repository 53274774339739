import axios from 'axios';

 
export const api = axios.create({
    // baseURL: "http://localhost:7010",
    baseURL: process.env.REACT_APP_API_BASE_URL,
    interceptors: {
      response: (response) => {
        return response;
      },
      error: (error) => {
        // Handle error here
        return Promise.reject(error);
      },
    },
  });
  
 