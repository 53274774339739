import React, { useState, useEffect } from "react";
import axios from 'axios';
const MultiSelect = () => {
 
    const [TypeofDisability, setTypeofDisability] = useState('');
    const [otherInput, setOtherInput] = useState('');
  
    const handleSelectChange3 = (e) => {
      setTypeofDisability(e.target.value);
  
      // Reset otherInput when a different option is selected
      if (e.target.value !== 'Other') {
        setOtherInput('');
      }
    };
  
    const handleOtherInputChange3 = (e) => {
      setOtherInput(e.target.value);
    };
  
    const handleSubmit = () => {
      if (TypeofDisability === 'Other' && otherInput.trim() === '') {
        alert('Please enter a value for Other');
        return;
      }

      // alert( TypeofDisability === 'Other' ? otherInput : TypeofDisability)
  
      // Use Axios to post the data to your backend
      // axios.post('/your-api-endpoint', {
      //   typeofDisability: TypeofDisability === 'Other' ? otherInput : TypeofDisability,
      // })
      // .then(response => {
      //   // Handle successful response
      // })
      // .catch(error => {
      //   // Handle error
      // });
    };
  
    return (
      <div>
        <select
          value={TypeofDisability}
          onChange={handleSelectChange3}
          className="form-control"
        >
          <option disabled value="">Select</option>
          <option value="Visual Impairment">Visual Impairment</option>
          <option value="Deaf / Hard of Hearing">Deaf / Hard of Hearing</option>
          <option value="Physical or Mobility Disability">Physical or Mobility Disability</option>
          <option value="Persons with Autism Spectrum Disorders">Persons with Autism Spectrum Disorders</option>
          <option value="Persons with Cerebral palsy">Persons with Cerebral palsy</option>
          <option value="Persons with Albinism">Persons with Albinism</option>
          <option value="Persons with Epilepsy">Persons with Epilepsy</option>
          <option value="Persons with Mental Health Conditions">Persons with Mental Health Conditions</option>
          <option value="Persons with Speech Impairment">Persons with Speech Impairment</option>
          <option value="Persons with Multiple Disabilities">Persons with Multiple Disabilities</option>
          <option value="Other">Other</option>
        </select>
  
        {TypeofDisability === 'Other' && (
          <input
            type="text"
            value={otherInput}
            onChange={handleOtherInputChange3}
            className="form-control"
            placeholder="Enter other disability"
          />
        )}
  
        <button onClick={handleSubmit}>Submit</button>
      </div>
    );
  }
   
  
  
export default MultiSelect;
