
import React, { useEffect, useRef, useState } from 'react';
import { Link, Outlet } from "react-router-dom";
import Swal from 'sweetalert2';

import axios from 'axios';


import Choices from 'choices.js'; // Import Choices.js library
// import 'choices.js/public/assets/styles/choices.min.css'; // Import Choices.js CSS

import Select from 'react-select';



import "./mpdStyle.css";
import dlogo from '../Pent_register/noimage.png';
import mpdws from "./mpwds1.jpg";


import { api } from '../Api';

function Upd_mpwds() {


  const [allAreas, setAllAreas] = useState([]);
  // const [churchArea, setChurchArea] = useState('');

  // api.post('/api/register', data, {
  //   headers: {
  //     'Accept': 'application/json',
  //     'Content-Type': 'application/x-www-form-urlencoded',
  //     // 'Authorization': 'Bearer YOUR_TOKEN' // if needed
  //   }
  // })



  const getDataArea = async () => {
    try {
      const result = await api.get('/api/areas');
      setAllAreas(result.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    getDataArea();
  }, []);




  const [selectedOption, setSelectedOption] = useState(null);
  const options = [
    { value: 'wheelchair', label: 'Wheelchair' },
    { value: 'walker', label: 'Walker' },
    { value: 'White cane', label: 'White Cane' },
    { value: 'crutches', label: 'Crutches' },
    { value: 'glasses', label: 'Glasses' },
    { value: 'hearing aids', label: 'Hearing aids' },
    { value: 'speech generating_devices', label: 'Speech-generating devices' },
    { value: 'prosthesis', label: 'Prosthesis' },
    { value: 'computer screen_reader', label: 'Screen reader' },
    { value: 'magnifying software', label: 'Magnifying software' },
    { value: 'closed captioning', label: 'Closed captioning' },
    // { value: 'sign language_interpreter', label: 'Sign language interpreter' }
  ];

  const handleChange3 = (selectedOption) => {
    setSelectedOption(selectedOption);
  };


  const handleSubmit2 = (event) => {
    event.preventDefault();
    // console.log(selectedOption);
    // console.log(selectedOption.map(option => option.label).join(', '));
    alert(selectedOption.map(option => option.label).join(', '))
  };



  const [TypeofDisability, setTypeofDisability] = useState('');
  const [otherInput, setOtherInput] = useState('');
  const [otherInput4, setOtherInput4] = useState('');

  const handleSelectChange3 = (e) => {
    setTypeofDisability(e.target.value);

    // Reset otherInput when a different option is selected
    if (e.target.value !== 'Other') {
      setOtherInput('');
    }
  };

  const handleOtherInputChange3 = (e) => {
    setOtherInput(e.target.value);
  };

  const handleOtherInputChange4 = (e) => {
    setOtherInput4(e.target.value);
  };


  const handleSubmit3 = () => {
    if (TypeofDisability === 'Other' && otherInput.trim() === '') {
      alert('Please enter a value for Other');
      return;
    }

    alert(TypeofDisability === 'Other' ? otherInput : TypeofDisability)

    // Use Axios to post the data to your backend
    // axios.post('/your-api-endpoint', {
    //   typeofDisability: TypeofDisability === 'Other' ? otherInput : TypeofDisability,
    // })
    // .then(response => {
    //   // Handle successful response
    // })
    // .catch(error => {
    //   // Handle error
    // });
  };


  const [loading, setLoading] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [birthDate, setBirthDate] = useState("")
  const [gender, setGender] = useState("");
  const [copMember, setCopMember] = useState("");
  const [churchArea, setChurchArea] = useState("");
  const [district, setDistrict] = useState("");
  const [local, setLocal] = useState("");

  const [occupation, setOccupation] = useState("");
  const [disable, setDisable] = useState("");
  const [maritalStatus, setMaritalStatus] = useState("");
  const [device, setDevice] = useState("");
  const [disableOther, setDisableOther] = useState("");
  const [emergency, setEmergency] = useState("");
  const [base64Image, setBase64Image] = useState('');
  const [image, setImage] = useState(null);
  const [DigitalAddress, setDigitalAddress] = useState("");
  const [Address, setAddress] = useState("");








  const [baptismWaterChecked, setBaptismWaterChecked] = useState(false);
  const [baptismSpiritChecked, setBaptismSpiritChecked] = useState(false);
  const [baptismNoneChecked, setBaptismNoneChecked] = useState(false);















  const handleBaptismWaterChange = (e) => {
    setBaptismWaterChecked(e.target.checked);
    if (e.target.checked) {
      setBaptismNoneChecked(false);
    }
  };

  const handleBaptismSpiritChange = (e) => {
    setBaptismSpiritChecked(e.target.checked);
    if (e.target.checked) {
      setBaptismNoneChecked(false);
    }
  };

  const handleBaptismNoneChange = (e) => {
    setBaptismNoneChecked(e.target.checked);
    if (e.target.checked) {
      setBaptismWaterChecked(false);
      setBaptismSpiritChecked(false);
    }
  };





  // ============================================


  const handleSelectChange = (e) => {
    const value = e.target.value;
    setDisable(value);

    // Show the input field if "Other" is selected
    setDisableOther(value === 'other');
  };

  // ==========================================
  const handleFileInputChange = (e) => {
    const selectedImage = e.target.files[0];

    if (selectedImage) {
      if (selectedImage.size > 400 * 1024) {
        // File size is larger than 400KB, clear the input
        e.target.value = '';
        setImage(null);
        // alert('File size should be no larger than 400KB');
        const Toast = Swal.mixin({
          toast: true,
          position: 'top-left',
          iconColor: 'red',
          customClass: {
            popup: 'colored-toast'
          },
          showConfirmButton: false,
          timer: 5500,
          timerProgressBar: true
        })
        Toast.fire({
          type: "error",
          icon: 'error',
          color: "red",
          //   background: 'red',
          title: 'File size should be no larger than 400KB ',
          // text: 'Something went wrong!', email and password must be filled out
        })

        return;
      }


      const imageURL = URL.createObjectURL(selectedImage);
      setImage(imageURL);
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        setBase64Image(reader.result);
      };
    }
  };

  // ================VALIDATION===================
  const getErrorMessage = () => {
    if (currentStep === 1) {
      if (!firstName || !/^[a-zA-Z0-9\s\-_:]+$/.test(firstName)) {
        return " First name required!!!!.";
      }

      if (!lastName || !/^[a-zA-Z0-9\s\-_:]+$/.test(lastName)) {
        return "Last name required!.";
      }
      // if (!email || !/^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/.test(email)) {
      //   return "Please check your email and try again .";
      // }
      if (!birthDate || !/^\d{4}-\d{2}-\d{2}$/.test(birthDate)) {
        return "Invalid date of birth || check and try again .";
      }

      if (!phoneNumber || !/^\+?[\d\s\-]+$/.test(phoneNumber)) {
        return "Phone number validation error.";
      }

      if (!gender || !/^[a-zA-Z0-9\s\-_:]+$/.test(gender)) {
        return "Choose a Gender.";
      }



    }

    if (currentStep === 2) {
      if (!district || !/^[a-zA-Z0-9\s\-_:]+$/.test(district)) {
        return "  Church  district is required ";
      }
      if (!local || !/^[a-zA-Z0-9\s\-_:]+$/.test(local)) {
        return " Church Local is required ";
      }

      if (!churchArea || !/^[a-zA-Z0-9\s\-_:]+$/.test(churchArea)) {
        return " Church Area is required ";
      }


      if (!copMember || !/^[a-zA-Z0-9\s\-_:]+$/.test(copMember)) {
        return "  Member status is required ";
      }

      if (!maritalStatus || !/^[a-zA-Z0-9\s\-_:]+$/.test(maritalStatus)) {
        return "Marital status is required  ";
      }

      if (!Address || !/^[a-zA-Z0-9\s\-_:]+$/.test(Address)) {
        return "Address is required ";
      }

      if (!occupation || !/^[a-zA-Z0-9\s\-_:]+$/.test(occupation)) {
        return "Occupation is required ";
      }


    }

    if (currentStep === 3 && (!TypeofDisability || !emergency)) {
      return false;
    }

    return null;  // No errors found
  };
  // ====================================================



  const isFormValid = () => {
    return getErrorMessage() === null;
  };



  const nextStep = () => {
    // const errorMessage = getErrorMessage();
    // if (errorMessage) {
    //   const Toast = Swal.mixin({
    //     toast: true,
    //     position: 'top-right',
    //     iconColor: 'red',
    //     customClass: {
    //       popup: 'colored-toast'
    //     },
    //     showConfirmButton: false,
    //     timer: 5500,
    //     timerProgressBar: true
    //   });
    //   Toast.fire({
    //     type: "error",
    //     icon: 'error',
    //     title: errorMessage,
    //   });

    //   return;
    // }
    setCurrentStep(currentStep + 1);
  };

  // =======================================================

  const prevStep = () => {
    setCurrentStep(currentStep - 1);
  };
  // ============================================================

  const handleToast = (icon, title, color) => {
    const Toast = Swal.mixin({
      toast: true,
      position: 'top-right',
      iconColor: color,
      customClass: {
        popup: 'colored-toast'
      },
      showConfirmButton: false,
      timer: 5500,
      timerProgressBar: true
    });

    Toast.fire({ icon, title });
  }




  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (!phoneNumber || !firstName) {
      setLoading(false);
      handleToast('error', 'Form validation error!', 'red');
    } else {
      const data = {
        base64Image: base64Image,
        firstName: firstName,
        lastName: lastName,
        email: email,
        birthDate: birthDate,
        phoneNumber: phoneNumber,
        gender: gender,


        //  churchArea: showOtherChurchArea ? otherChurchArea : churchArea,
        district: district,
        local: local,
        copMember: copMember,
        // baptiseByWater:isChecked.baptiseByWater,
        // baptiseBySpirit:isChecked.baptisedBySpirit,
        // not_Baptised:isChecked.none,
        // address:address,
        occupation: occupation,
        disable: disable,
        maritalStatus: maritalStatus,
        device: device,
        disableOther: disableOther,
        emergency: emergency


      };

      await axios.post('http://localhost:9900/api/origin_mpwdsData', data, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded',
          // 'Authorization': 'Bearer YOUR_TOKEN' // if needed
        }
      })
        .then(res => {
          if (res && res.data && res.data.message) {
            handleToast('success', res.data.message, 'green');
            setImage(/* Your dlogo or any other image */);
            setLoading(false);
            setTimeout(() => { window.location = "/thankyou"; }, 7000);
          } else if (res && res.response && res.response.data && res.response.data.response && res.response.data.response.message) {
            setLoading(false);
            handleToast('error', 'Error: ' + res.response.data.response.message, 'red');
          } else {
            setLoading(false);
            handleToast('error', 'Error: An unknown error occurred', 'red');
          }
        })
        .catch(err => {
          setLoading(false);



          setLoading(false);

          // First, fix the typo: change 'errponse' to 'response'
          if (err && err.response && err.response.data && err.response.data.message) {
            handleToast('error', 'Error: ' + err.response.data.message, 'red');
          } else {
            handleToast('error', 'Error:  ', 'red' + err.response.data.message);
          }




          // const errorMessage = err.response?.data?.message || 'An error occurred';
          // handleToast('error', 'Error: ' + errorMessage, 'red');
        });
    }

    // setLoading(true);
  }




  // =========================================================================


  // ======================image upload-------------------
  // const [selectedImage, setSelectedImage] = useState(null);

  // const handleImageChange = (event) => {
  //   const file = event.target.files[0];
  //   setSelectedImage(file);
  // };

  // const handleUpload = () => {
  //   if (selectedImage) {
  //     // Implement your upload logic here
  //     console.log('Uploading image:', selectedImage);
  //     // You can use FormData and send the image to a server
  //   }
  // };





  return (
    <>
      {/* ---------------Navigation------------ */}
      <div>
        <div className='horizontal-nav'>
          <nav className="navbar navbar-expand-lg">
            <div id="nx-nav" className="container">
              <i style={{ color: "white" }} class='bx bx-menu bx-lg' className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                {/* <span className="navbar-toggler-icon"></span> */}
                <i class='bx bx-menu bx-lg'></i>
              </i>
              <div className="collapse navbar-collapse" id="navbarNavDropdown">
                <ul className="navbar-nav">
                  <li className="nav-item active">
                    <Link className="nav-link" to="/">Home</Link>
                  </li>
                  <li id='nav-li-id' >
                    <Link id='log-link' to="/"> <a href="####">
                      <img src={mpdws} alt="logo" id='logo' /></a>
                    </Link>
                  </li>
                </ul>


                {/* ---------------------- */}
                <div className="collapse navbar-collapse" id="Second">
                  <ul className='navbar-nav'>
                    <li className='nav-item'>
                      <Link to="/register" className='nav-link'><a href="####">Register</a></Link>
                    </li>
                  </ul>
                </div>

              </div>
            </div>
          </nav>

          <Outlet />
        </div>
      </div>

      {/* ---------------Navigation------------ */}
      {/* ================================================================================== */}
      &nbsp;
      &nbsp;
      <div id="#signup-sec" className="container-fluid">


        <form onSubmit={handleSubmit}>
          {/* Step 1 */}
          {currentStep === 1 && (
            <>
              <div className="form-group">
                <h1 id="h1" >Registration portal for the Ministry of Persons with Disabilities (MPWDs)</h1>
                {/* =========================================================== */}
                <div id='imgUpload'>
                  <div>
                    <div>
                      <div id='imgUpload'>
                        <div>
                          <div>
                            <img width="100" height="100" id="previewImg"
                              src={image || dlogo} alt='copitguild'
                              class="img-responsive rounded-circle center-block d-block mx-auto"
                              onError={(e) => { e.target.onerror = null; e.target.src = dlogo }}
                            />

                            <input type="file" id="image" onChange={handleFileInputChange} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* =========================================================== */}
                <div class="row">
                  <div class="col-md-6">
                    <label>
                      FirstName :
                      <div id="fn">
                        <i style={{ marginTop: "16px" }} class='bx bx-user bx-sm '></i>
                        <input type="text" value={firstName}
                          onChange={(e) => setFirstName(e.target.value)}
                          className={!firstName ? "error" : ""}
                          class="form-control"
                          placeholder="First Name"
                          required />
                      </div>

                    </label>
                  </div>
                  {/* ========================================================= */}
                  <div class="col-md-6">
                    <label>
                      Last Name:
                      &nbsp;
                      <div id="fn">
                        <i style={{ marginTop: "16px" }} class='bx bxs-user bx-sm '></i>
                        <input type="text" value={lastName}
                          onChange={(e) => setLastName(e.target.value)}
                          placeholder="Last Name"
                          className={!lastName ? "error" : ""}
                          class="form-control" required />
                      </div>
                    </label>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-6">
                    <label>
                      Email:

                      <div id="fn">
                        <i style={{ marginTop: "16px" }}
                          class='bx bxs-envelope bx-sm'
                        ></i>

                        <input type="email" value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          placeholder="Enter valid email here"

                          class="form-control" />

                      </div>
                    </label>
                    {/* =========================================================== */}
                  </div>
                  <div class="col-md-6">
                    <label>
                      Date of Birth:
                      <div id="fn">
                        <i style={{ marginTop: "16px" }} class='bx bxs-calendar bx-sm'></i>
                        <input type="date" value={birthDate}
                          onChange={(e) => { setBirthDate(e.target.value) }}
                          class="form-control"
                          className="form-control"
                          required />
                      </div>
                    </label>
                    {/* ============================================================================== */}
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-6">

                    <label>
                      Phone Number:
                      <div id="fn">
                        <i style={{ marginTop: "16px" }} class='bx bxs-phone bx-sm'></i>
                        <input type="tel" value={phoneNumber}
                          onChange={(e) => setPhoneNumber(e.target.value)}
                          placeholder="Phone number eg:020392XXXX"
                          className={!phoneNumber ? "error" : ""}
                          class="form-control" required />
                      </div>
                    </label>

                    {/* ========================================================================== */}
                  </div>
                  <div class="col-md-6">

                    <label>
                      Sex
                      <div id="fn">
                        <i style={{ marginTop: "16px" }} class='bx bx-male-female  bx-sm' ></i>
                        <select value={gender} onChange={(e) => setGender(e.target.value)}
                          className={!gender ? "error" : ""} required>
                          <option value="">--Select--</option>
                          <option value="Male">Male</option>
                          <option value="Female">Female</option>
                        </select>
                      </div>
                    </label>

                  </div>
                </div>
              </div>
              <button type="button" className="btn btn-primary" onClick={nextStep}>Next</button>
            </>
          )}

          {/* ========================================================================================= */}
          {/* Step 2 */}
          {currentStep === 2 && (
            <>
              <h1 id="h1" >Registration portal for the Ministry of Persons with Disabilities (MPWDs)</h1>

              {/* ====================================================== */}

              <div class="row">
                <div class="col-md-6">
                  <label>
                    Select a Church Area:
                    <div id="fn">
                      <i style={{ marginTop: "16px" }} class='bx bxs-home bx-sm'></i>

                      <select

                        value={churchArea}
                        onChange={(e) => setChurchArea(e.target.value)}
                        className={!churchArea ? "error" : ""} required
                      >
                        <option value="">Select Areas...</option>
                        {allAreas.map((element) => (
                          <option key={element.id} value={element.area}>
                            {element.area}
                          </option>
                        ))}
                      </select>
                    </div>
                  </label>


                </div>

                <div class="col-md-6">

                  <label>

                    District:
                    <div id="fn">
                      <i style={{ marginTop: "16px" }} class='bx bxs-book bx-sm'></i>

                      <input type="text" class="form-control"
                        placeholder="Enter your district" value={district}
                        onChange={(e) => setDistrict(e.target.value)}
                        className={!district ? "error" : ""} required
                      />
                    </div>
                  </label>
                </div>

              </div>




              <div class="row">
                <div class="col-md-6">
                  <div>
                    <label>
                      Local:
                      <div id="fn">
                        <i style={{ marginTop: "16px" }} class='bx bxs-spreadsheet bx-sm'></i>

                        <input type="text" class="form-control"
                          className={!local ? "error" : ""} required
                          placeholder="Enter your Local" value={local}
                          onChange={(e) => setLocal(e.target.value)} />
                      </div>
                    </label>
                  </div>
                </div>


                <div class="col-md-6">

                  <label>
                    Member Status:
                    <div id="fn">
                      <i style={{ marginTop: "16px" }} class='bx bxs-cabinet bx-sm'></i>

                      <select
                        className={!copMember ? "error" : ""} required
                        value={copMember} onChange={(e) => setCopMember(e.target.value)}>
                        <option value="">--Select--</option>

                        <option value="Member">Member</option>
                        <option value="Minister">Minister</option>
                      </select>
                    </div>
                  </label>
                </div>
              </div>


              <div className="row mb-2">
                <label>
                  Baptism </label>
                <div style={{ backgroundColor: '#F4F6F6' }}>
                  <div style={{ display: baptismNoneChecked ? "none" : "block" }}>

                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="Baptism_Water1"
                      name="Baptism_Water1"
                      value="Baptism Water"
                      checked={baptismWaterChecked}
                      onChange={handleBaptismWaterChange}
                    />
                    <>   Water </>
                    &nbsp;&nbsp;&nbsp;&nbsp;  &nbsp;&nbsp;&nbsp;&nbsp;  &nbsp;&nbsp;&nbsp;&nbsp;
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="Baptism_Spirit2"
                      name="Baptism_Spirit2"
                      value="Baptism Spirit"
                      checked={baptismSpiritChecked}
                      onChange={handleBaptismSpiritChange}
                    />
                    Holy Spirit

                  </div>
                  <span> <br /></span>
                  {!baptismWaterChecked && !baptismSpiritChecked && (
                    <>

                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="none"
                        name="none3"
                        value="none"
                        checked={baptismNoneChecked}
                        onChange={handleBaptismNoneChange}
                      />
                      <> None of the above </>
                      <br />
                    </>
                  )}

                </div>


              </div>


              {/* =======================Address============ */}
              <div className="d-flex">
                <label>
                  Address
                  <div id="fn">
                    <i style={{ marginTop: "16px" }} class='bx bxs-chevrons-up bx-sm'></i>

                    <textarea
                      rows="1"
                      type="text"
                      value={Address}
                      className="form-control"
                      onChange={(e) => setAddress(e.target.value)}
                      placeholder="Residential Address"
                      required


                    />
                  </div>
                </label>
                &nbsp;
                <label>
                  &nbsp;
                  <div id="fn">

                    <i style={{ marginTop: "16px" }} class='bx bxs-pin bx-sm'></i>

                    <textarea
                      rows="1"
                      type="text"
                      value={DigitalAddress}
                      className="form-control"
                      onChange={(e) => setDigitalAddress(e.target.value)}
                      placeholder="Digital Address "
                      required />
                  </div>
                </label>
              </div>
              {/* ========================================================== */}
              {/* =======================Occupation============ */}


              <div class="row">
                <div class="col-md-6">
                  <label>
                    Occupation
                    <div id="fn">
                      <i style={{ marginTop: "16px" }} class='bx bxs-briefcase-alt bx-sm'></i>

                      <textarea
                        rows="1"
                        type="text"
                        value={occupation}
                        className="form-control"
                        onChange={(e) => setOccupation(e.target.value)}
                        placeholder="Whats your occupation"
                        required />
                    </div>
                  </label>
                </div>

                {/* ===================================== */}
                <div class="col-md-6">

                  <label>
                    Marital Status
                    <div id="fn">
                      <i style={{ marginTop: "16px" }} class='bx bxs-group bx-sm'></i>

                      <select
                        className={!maritalStatus ? "error" : ""} required
                        value={maritalStatus}
                        onChange={(e) => setMaritalStatus(e.target.value)}>
                        <option value="">---Select----</option>
                        <option value="single">Single</option>
                        <option value="married">Married</option>
                        <option value="divorced">Divorced</option>
                        <option value="widowed">Widowed</option>
                        <option value="Disability-related separation">Disability-related separation</option>

                      </select>
                    </div>
                  </label>
                </div>
                {/* ============================================= */}


              </div>

              <button type="button" className="btn btn-secondary" onClick={prevStep}>Previous</button>
              <button type="button" className="btn btn-primary" onClick={nextStep}>Next</button>

            </>
          )
          }

          {/* Step 3 */}
          {currentStep === 3 && (
            <>
              <div className="form-group">
                <h6><u>Finish up </u> </h6>


                <div class="col-md-12">
                  <label> Type of Disability
                    <div id="fn">
                      <i style={{ marginTop: "16px" }} class='bx bx-accessibility bx-sm'></i>

                      <select
                        className={!TypeofDisability ? "error" : ""} required

                        value={TypeofDisability}
                        onChange={handleSelectChange3}
                        class="form-control"
                      >
                        <option disabled value="">Select Type of Disability</option>
                        <option value="Visual Impairment">Visual Impairment</option>
                        <option value="Deaf / Hard of Hearing">Deaf / Hard of Hearing</option>
                        <option value="Physical or Mobility Disability">Physical or Mobility Disability</option>
                        <option value="Person with Autism Spectrum Disorders">Person with Autism Spectrum Disorders</option>
                        <option value="Person with Cerebral palsy">Person with Cerebral palsy</option>
                        <option value="Person with Albinism">Person with Albinism</option>
                        <option value="Person with Epilepsy">Person with Epilepsy</option>
                        <option value="Person with Mental Health Conditions">Person with Mental Health Conditions</option>
                        <option value="Person with Speech Impairment">Person with Speech Impairment</option>
                        <option value="Person with Multiple Disabilities">Person with Multiple Disabilities</option>
                        <option value="Other">Other</option> </select>
                    </div>


                    <div class="col-md-12">
                      {TypeofDisability === 'Person with Multiple Disabilities' && (
                        <input
                          type="text"
                          value={otherInput4}
                          onChange={handleOtherInputChange4}
                          className="form-control"
                          placeholder="Enter all disabilities"
                        />
                      )}
                    </div>

                    <div class="col-md-12">
                      {TypeofDisability === 'Other' && (
                        <input
                          type="text"
                          value={otherInput}
                          onChange={handleOtherInputChange3}
                          className="form-control"
                          placeholder="Enter other disability"
                        />
                      )}
                    </div>

                  </label>
                </div>
                {/* <button onClick={handleSubmit3}>Submit</button> */}


                {/* ============================= */}


                <div class="input-group mb-3">
                  <label> Assistive device in use </label>
                  <div class="input-group mb-3">
                    <span class="input-group-text" id="basic-addon1">

                      <i style={{ marginTop: "16px" }} class='bx bx-handicap  bx-sm'></i>
                    </span>
                    <Select
                      isMulti
                      options={options}
                      onChange={handleChange3}
                      className="form-control"
                      classNamePrefix="Select all devices you are using."
                      placeholder="Select all devices you are using"
                    />

                  </div></div>



                <div class='row'>

                  <label>
                    Emergency contact name
                    <div class='col-md-12 mb-3'>
                      <div id="fn">
                        <i style={{ marginTop: "16px" }} class='bx bxs-group bx-sm'></i>


                        <input
                          type="text"
                          className="form-control"
                          placeholder="Contact name in case of an emergency"
                          value={emergency}
                          onChange={(e) => { setEmergency(e.target.value) }}
                          required
                        />
                      </div>
                    </div>
                  </label>


                  <label>
                    Emergency contact Number :
                    <div id="fn">
                      <i style={{ marginTop: "16px" }} class='bx bxs-phone bx-sm'></i>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Primary contact number in case of emergency"
                        value={emergency}
                        onChange={(e) => { setEmergency(e.target.value) }}
                        required
                      />
                    </div>
                  </label>



                </div>

                <br />
                <div className="form-group">
                  <label style={{ color: "red", marginLeft: "10px" }}><u>Applicant's Consent</u></label>
                  <h6 htmlFor="terms">
                    By completing this form, I hereby provide my explicit consent to
                    the COP Ministry to Persons With Disabilities
                    (MPWDs) to use my personal information for the purposes of this registration.
                  </h6>
                </div>
                <hr />
                <br />

                <button type="button" className="btn btn-secondary" onClick={prevStep}>Previous</button>
                <button type="submit" className="btn btn-primary" disabled={!isFormValid()}>Submit</button>
              </div>
            </>

          )}
        </form >
      </div >



    </>

  );
}


export default Upd_mpwds;