import React, { useState } from "react";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
// import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Form, Button, Navbar, Nav } from "react-bootstrap";
// import PentReg from "./Mpwd_excel/PentReg";
// import PentReg from "./Pent_register/FormRegister_1"


import PentReg from "./PeWG/FormRegister"

 
   import CheckboxGroup from "./Register_MPWDs/CheckboxGroup";

  
    import Updmpwds from "./Register_MPWDs/Upd_mpwds";
    import MultiSelect from "./Register_MPWDs/MultiSelect";
  

function App() {
 
  return( 
    <>
    {/* <FormRegister /> */}
   
    <Router>
        <Routes>
       <Route path='/index' element={ <PentReg  />} />
       <Route path='*' element={ <PentReg  />} />
        <Route path='/sjsjsj' element={ <PentReg  />} />
        <Route path='/ashshs' element={ <PentReg  />} />  
        <Route path='/mm' element={ <MultiSelect   />} />  
        <Route path='/mpwd' element={ <Updmpwds />} />  
        <Route path='/abcc' element={ <CheckboxGroup />} />  
    
        </Routes>
      </Router>
 
 
    </>
  )
}
export default App;


